import { ButtonBase, CircularProgress } from "@mui/material";
import React from "react";
import { useTheme } from "styled-components";
import Box from "../Box";

function Button({ variant = "filled", children, sx, isLoading, ...props }) {
  const { colors, fontSizes, spacing, variants } = useTheme();
  return (
    <ButtonBase
      sx={{
        ...variants?.button?.[variant],
        ...sx,
      }}
      {...props}
    >
      {children}
      {isLoading && (
        <Box
          sx={{
            display: "inline",
          }}
        >
          <CircularProgress
            size={fontSizes.m}
            sx={{
              color: colors.white,
              marginLeft: spacing.xxxs,
              position: "absolute",
              top: "25%",
            }}
          />
        </Box>
      )}
    </ButtonBase>
  );
}

export default Button;
