import { createTheme } from "@mui/material";
import defaultTheme, { generateVariants } from "./default";

const primaryAccent = defaultTheme.colors.lighterViolet;
const primaryAccentLight = defaultTheme.colors.lightestViolet;

const colors = { ...defaultTheme.colors, primaryAccent, primaryAccentLight };

const variants = generateVariants({
  borders: defaultTheme.borders,
  colors,
  fontFamily: defaultTheme.fontFamily,
  fontSizes: defaultTheme.fontSizes,
  fontWeights: defaultTheme.fontWeights,
  radii: defaultTheme.radii,
  spacing: defaultTheme.spacing,
});

const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: primaryAccent,
    },
  },
});

export default { ...defaultTheme, colors, muiTheme, variants };
