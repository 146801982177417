import PropTypes from "prop-types";
import React from "react";
import { AuthContextProvider } from "../../../contexts/auth-context";
import { FirebaseProvider } from "../../../contexts/firebase-context";
import ThemeProvider from "../../wrappers/ThemeProvider";
import WorkflowProvider from "../../wrappers/WorkflowProvider";

const AppBase = ({ children, location, app, au, fn, cs, db }) => {
  return (
    <ThemeProvider location={location}>
      <AuthContextProvider au={au} fn={fn}>
        <FirebaseProvider app={app} au={au} cs={cs} db={db} fn={fn}>
          <WorkflowProvider location={location}>{children}</WorkflowProvider>
        </FirebaseProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
};

AppBase.propTypes = {
  app: PropTypes.object.isRequired,
  au: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  cs: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  fn: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AppBase;
