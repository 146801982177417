import { USER_ROLES } from "./constants";

export const checkIsAdmin = (roles = []) => {
  return (
    (roles?.includes?.(USER_ROLES.VOICE_ADMIN) ||
      roles?.includes?.(USER_ROLES.BUSINESS_ADMIN)) ??
    false
  );
};

export const checkIsVoiceAdmin = (roles = []) => {
  return roles?.includes?.(USER_ROLES.VOICE_ADMIN) ?? false;
};

export const checkIsBusinessAdmin = (roles = []) => {
  return roles?.includes?.(USER_ROLES.BUSINESS_ADMIN) ?? false;
};

export const checkIsOwner = (roles = []) => {
  return roles?.includes?.(USER_ROLES.BUSINESS_OWNER) ?? false;
};
