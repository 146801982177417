import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Divider, IconButton, Modal } from "@mui/material";
import { Link } from "gatsby";
import React, { useState } from "react";
import { useTheme } from "styled-components";
import { useIsHelloPage } from "../../hooks/IsHelloPage";
import facebookIcon from "../../images/facebook.svg";
import googleIcon from "../../images/google.svg";
import twitterIcon from "../../images/twitter.svg";
import { ROUTES, SOCIAL_PROVIDERS } from "../../utilities/constants";
import Box from "../base/Box";
import Button from "../base/Button";

const SocialButton = ({ provider, signInHandler, disabled, icon }) => {
  const { colors, spacing, borders, radii, fontSizes } = useTheme();
  const [loading, setLoading] = useState(false);

  return (
    <Button
      disabled={disabled || loading}
      onClick={async () => {
        try {
          setLoading(true);
          await signInHandler();
        } catch (e) {
          console.log("Error while login", e);
        }
        setLoading(false);
      }}
      sx={{
        ":hover": {
          borderColor: colors.lightBlack,
        },
        alignItems: "center",
        backgroundColor: colors.white,
        border: borders.normal,
        borderColor: colors.gray10,
        borderRadius: radii.xxs,
        color: colors.lightBlack,
        display: "flex",
        opacity: disabled ? 0.4 : 1,
        padding: spacing.s,
        width: "100%",
      }}
    >
      {loading ? (
        <CircularProgress
          size={fontSizes.ml}
          sx={{
            left: 10,
            position: "absolute",
            top: 10,
          }}
        />
      ) : (
        <Box
          component="img"
          src={icon}
          sx={{
            height: fontSizes.ml,
            left: 10,
            position: "absolute",
            top: 10,
            width: fontSizes.ml,
          }}
        />
      )}
      <Box>{`Continue With ${provider}`}</Box>
    </Button>
  );
};

function LoginModal({ open, onClose, providers, signIn, sx }) {
  const { colors, spacing, radii, fontSizes, fontWeights } = useTheme();
  const [activeProvider, setActiveProvider] = useState("");
  const isHelloPage = useIsHelloPage();

  const getIcon = (provider) => {
    switch (provider) {
      case SOCIAL_PROVIDERS.TWITTER:
        return twitterIcon;
      case SOCIAL_PROVIDERS.FACEBOOK:
        return facebookIcon;
      default:
        return googleIcon;
    }
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          bgcolor: "white",
          borderRadius: radii.s,
          left: "50%",
          outline: "none",
          overflow: "hidden",
          position: "absolute",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: ["90%", "540px"],
          ...sx,
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            marginY: [spacing.m, , spacing.lg],
          }}
        >
          {!isHelloPage && (
            <IconButton
              onClick={onClose}
              sx={{
                alignSelf: "flex-start",
                color: "black",
                height: [fontSizes.m, , fontSizes.ml],
                left: [spacing.s, , spacing.m],
                marginTop: [spacing.xxs, , spacing.tiny],
                position: "absolute",
                top: [spacing.s, , spacing.lg],
                width: [fontSizes.m, , fontSizes.ml],
              }}
            >
              <CloseIcon
                sx={{
                  height: [fontSizes.m, , fontSizes.ml],
                  width: [fontSizes.m, , fontSizes.ml],
                }}
              />
            </IconButton>
          )}
          <Box
            sx={{
              fontSize: [fontSizes.s, , fontSizes.lg],
              margin: "auto",
            }}
          >
            {isHelloPage
              ? "Sign up to see your Conversation!"
              : "Log in or Sign up to Post your Conversation"}
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: spacing.m,
            marginTop: spacing.m,
            marginX: "auto",
            width: "80%",
          }}
        >
          {providers.map((provider) => (
            <SocialButton
              key={provider}
              disabled={activeProvider !== provider && activeProvider !== ""}
              icon={getIcon(provider)}
              provider={provider}
              signInHandler={async () => {
                setActiveProvider(provider);
                await signIn(provider);
                setActiveProvider("");
              }}
            />
          ))}
        </Box>
        <Box
          sx={{
            color: colors.gray2,
            fontSize: fontSizes.s,
            fontWeight: fontWeights.light,
            marginBottom: spacing.lg,
            marginTop: spacing.lg,
            marginX: "auto",
            textAlign: "center",
            width: ["70%", , "100%"],
          }}
        >
          We do not store any data related to your social logins.
        </Box>
        <Box
          sx={{
            backgroundColor: colors.gray7,
            display: "flex",
            gap: spacing.s,
            justifyContent: "center",
            paddingY: spacing.m,
          }}
        >
          <Box
            component={Link}
            sx={{
              color: colors.lightBlack,
              fontSize: fontSizes.ss,
              fontWeights: fontWeights.light,
            }}
            target="_blank"
            to={ROUTES.TERMS_AND_CONDITIONS}
          >
            Terms of Service
          </Box>
          <Divider
            orientation="vertical"
            sx={{ borderColor: colors.lightBlack, height: fontSizes.s }}
          />
          <Box
            component={Link}
            sx={{
              color: colors.lightBlack,
              fontSize: fontSizes.ss,
              fontWeights: fontWeights.light,
            }}
            target="_blank"
            to={ROUTES.PRIVACY_POLICY}
          >
            Privacy Policy
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default LoginModal;
