import { trim } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import WorkflowContext from "../../../contexts/workflow-context";
import {
  ADMIN_ROUTES,
  OWNER_ROUTES,
  VOICE_ROUTES,
  WORKFLOWS,
} from "../../../utilities/constants";

const getWorkflow = (location) => {
  const routeArray = trim(location.pathname, "/").split("/");
  const route = routeArray?.[0];
  if (VOICE_ROUTES.includes(route)) return WORKFLOWS.PRESIDENTS;
  if (ADMIN_ROUTES.includes(route)) return WORKFLOWS.ADMIN;
  if (OWNER_ROUTES.includes(route)) return WORKFLOWS.OWNER;
  return WORKFLOWS.BUSINESSES;
};

const WorkflowProvider = ({ children, location }) => {
  const workflow = getWorkflow(location);
  return (
    <WorkflowContext.Provider value={workflow}>
      {children}
    </WorkflowContext.Provider>
  );
};

WorkflowProvider.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default WorkflowProvider;
