import PropTypes from "prop-types";
import React from "react";
import "../../styles/global.css";

// Pass Firebase utilities to children
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import config from "../../config.json";
import AppBase from "../base/AppBase";

const app = firebase.initializeApp(config.firebase);

const db = firebase.firestore();
const cs = firebase.storage();
const fn = firebase.functions();
const au = getAuth(app);

if (typeof window !== "undefined") {
  if (config?.enableAppcheckDebug) {
    // eslint-disable-next-line no-restricted-globals
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
  initializeAppCheck(app, {
    isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
    provider: new ReCaptchaV3Provider(config.appCheck.siteKey),
  });
}
// to connect local to emulator functions
// fn.useEmulator('localhost', 5001);
const App = ({ children, location }) => {
  return (
    <AppBase app={app} au={au} cs={cs} db={db} fn={fn} location={location}>
      {React.cloneElement(children, { au, cs, db, fn })}
    </AppBase>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default App;
