import { useLocation } from "@gatsbyjs/reach-router";
import { Alert, Snackbar } from "@mui/material";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  browserPopupRedirectResolver,
  getAdditionalUserInfo,
  linkWithPopup,
  onAuthStateChanged,
  signInAnonymously,
  signInWithCredential,
  signInWithPopup,
  signOut,
  updateProfile,
} from "firebase/auth";
import { navigate } from "gatsby";
import { find, get } from "lodash";
import PropTypes from "prop-types";
import React, { createContext, useContext, useEffect, useState } from "react";
import LoginModal from "../components/LoginModal";
import { useIsHelloPage } from "../hooks/IsHelloPage";
import { checkIsOwner } from "../utilities/auth";
import {
  AUTH_ERRORS,
  FIREBASE_AUTH_ERRORS,
  FIREBASE_FUNCTIONS,
  ROUTES,
  SOCIAL_PROVIDERS,
  USER_ROLES,
  UUID_PREFIXES,
} from "../utilities/constants";
import { getCallableFunctionOptions } from "../utilities/get-callable-function-options";
import localStorage from "../utilities/local-storage";
import { stringifySearchParams } from "../utilities/query-params";
import { attachUuidPrefix } from "../utilities/uuid";

const AuthContext = createContext();

export const AuthContextProvider = ({ children, au, fn }) => {
  // Note, firestore user is user data from firestore
  // It should not be used for verifying authentication as it is loaded after user
  // Firestore user should only be used for accessing user data stored on firestore
  const [user, setUser] = useState(null);
  const [firestoreUser, setFirestoreUser] = useState(null);
  const [userBusinesses, setUserBusinesses] = useState([]);
  const [userId, setUserId] = useState(null);
  const [userRoles, setUserRoles] = useState(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isSignInOn, setIsSignInOn] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [reloadPage, setReloadPage] = useState(true);
  // SessionId is only set while using hello flow, will be undefined otherwise
  const [sessionId, setSessionId] = useState();
  const isHelloPage = useIsHelloPage();
  const location = useLocation();
  // eslint-disable-next-line no-useless-escape
  const regex = /batopic:([^\/]+)/;
  const businessId = location?.pathname?.match(regex)?.[0];

  const upsertUser = fn?.httpsCallable(
    FIREBASE_FUNCTIONS.UPSERT_USER,
    getCallableFunctionOptions()
  );

  const getFirebaseUser = fn?.httpsCallable(
    FIREBASE_FUNCTIONS.GET_USER,
    getCallableFunctionOptions()
  );

  const getBusinesses = fn?.httpsCallable(
    FIREBASE_FUNCTIONS.GET_USER_BUSINESS,
    getCallableFunctionOptions()
  );

  const transferUserSession = fn?.httpsCallable(
    FIREBASE_FUNCTIONS.TRANSFER_USER_SESSION,
    getCallableFunctionOptions()
  );

  const triggerPubsubForGuest = fn?.httpsCallable(
    FIREBASE_FUNCTIONS.TRIGGER_PUBSUB_FOR_GUEST,
    getCallableFunctionOptions()
  );

  const parseResultforPayload = (result) => {
    return {
      user: {
        createdAt: result?.user?.metadata?.createdAt,
        displayName: result?.user?.displayName,
        email: result?.user?.email,
        emailVerified: result?.user?.emailVerified,
        isAnonymous: result?.user?.isAnonymous,
        isNewUser: get(result, "_tokenResponse.isNewUser", false),
        operationType: result?.operationType,
        photoURL: result?.user?.photoURL,
        providerData: result?.user?.providerData,
        providerId: result?.providerId,
        uid: result?.user?.uid,
        userRole: [USER_ROLES.END_USER],
      },
    };
  };

  // const getProviderforEmail = async (email) => {
  //   const methods = await fetchSignInMethodsForEmail(au, email);
  //   switch (methods?.[0]) {
  //     case GoogleAuthProvider.PROVIDER_ID:
  //       return SOCIAL_PROVIDERS.GOOGLE;
  //     case FacebookAuthProvider.PROVIDER_ID:
  //       return SOCIAL_PROVIDERS.FACEBOOK;
  //     case TwitterAuthProvider.PROVIDER_ID:
  //       return SOCIAL_PROVIDERS.TWITTER;
  //     default:
  //       return "NONE";
  //   }
  // };

  const handleError = async (e, provider) => {
    // const email = e?.customData?.email;
    switch (e?.code) {
      case FIREBASE_AUTH_ERRORS.DUPLICATE_EMAIL:
        // eslint-disable-next-line no-case-declarations
        // const existingProvider = await getProviderforEmail(email);
        // setError(
        //   `Account already created with ${existingProvider}, please use the same login`
        // );
        setError(AUTH_ERRORS.DUPLICATE_EMAIL);
        setShowError(true);
        throw e;
      case FIREBASE_AUTH_ERRORS.EMAIL_ALREADY_IN_USE:
        setError(AUTH_ERRORS.DUPLICATE_EMAIL);
        setShowError(true);
        throw e;
      default:
        console.log(`Error while Login with ${provider}`, e);
        throw e;
    }
  };

  const postLogin = (result) => {
    if (isHelloPage) {
      localStorage.setItem(
        "newUser",
        get(result, "_tokenResponse.isNewUser", true)
      );
      setIsLoginOpen(false);
      setReloadPage(false);
    } else {
      localStorage.setItem(
        "newUser",
        get(result, "_tokenResponse.isNewUser", false)
      );
      setIsLoginOpen(false);
      if (reloadPage) {
        window?.location?.reload();
      }
    }
  };

  const googleSignIn = async () => {
    try {
      if (user && user.isAnonymous) {
        const provider = new GoogleAuthProvider();
        await linkWithPopup(au.currentUser, provider)
          .then(async (result) => {
            // Accounts successfully linked
            const { user } = result;
            const { displayName, photoURL } = find(user?.providerData, [
              "providerId",
              GoogleAuthProvider.PROVIDER_ID,
            ]);
            await updateProfile(au.currentUser, { displayName, photoURL });

            await upsertUser({
              user: {
                ...parseResultforPayload(result).user,
                displayName,
                isAnonymousUser: false,
                isNewUser: true,
                name: displayName,
                photoURL,
              },
            });

            await triggerPubsubForGuest({
              sessionId,
              topicId: businessId,
              userId,
            });

            postLogin(result);
            navigate(
              `${ROUTES.BUSINESS_BASE}${businessId}?${stringifySearchParams({
                chat: true,
                skipToNext: true,
              })}`
            );

            return result;
          })
          .catch(async (error) => {
            if (error?.code === FIREBASE_AUTH_ERRORS.EXISTING_ACCOUNT) {
              const credential = GoogleAuthProvider.credentialFromError(error);
              const prevUserId = userId;
              const result = await signInWithCredential(au, credential);
              const newUserId = attachUuidPrefix(
                get(result, "user.uid", ""),
                UUID_PREFIXES.USER
              );
              await transferUserSession({
                existingUserId: newUserId,
                guestUserId: prevUserId,
                topicId: businessId,
              });
              await upsertUser(parseResultforPayload(result));
              setIsLoginOpen(false);
              navigate(
                `${ROUTES.BUSINESS_BASE}${businessId}?${stringifySearchParams({
                  chat: true,
                  skipToNext: true,
                })}`
              );
            } else {
              await handleError(error, SOCIAL_PROVIDERS.GOOGLE);
            }
          });
        return;
      }
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(
        au,
        provider,
        browserPopupRedirectResolver
      );

      // User is not anonymous, proceed with regular login logic
      await upsertUser(parseResultforPayload(result));
      postLogin(result);
      return result;
    } catch (e) {
      await handleError(e, SOCIAL_PROVIDERS.GOOGLE);
    }
  };

  const facebookSignIn = async () => {
    try {
      const provider = new FacebookAuthProvider();
      const result = await signInWithPopup(
        au,
        provider,
        browserPopupRedirectResolver
      );
      await upsertUser(parseResultforPayload(result));
      postLogin(result);
      return result;
    } catch (e) {
      await handleError(e, SOCIAL_PROVIDERS.FACEBOOK);
    }
  };

  const twitterSignIn = async () => {
    try {
      if (user && user.isAnonymous) {
        const provider = new TwitterAuthProvider();
        await linkWithPopup(au.currentUser, provider)
          .then(async (result) => {
            // Accounts successfully linked
            const { user } = result;
            const { displayName, photoURL } = find(user?.providerData, [
              "providerId",
              TwitterAuthProvider.PROVIDER_ID,
            ]);
            await updateProfile(au.currentUser, { displayName, photoURL });

            const additonalInfo = getAdditionalUserInfo(result);
            await upsertUser({
              user: {
                ...parseResultforPayload(result).user,
                displayName,
                email: additonalInfo?.profile?.email,
                isAnonymousUser: false,
                isNewUser: true,
                photoURL,
                username: additonalInfo?.username,
              },
            });

            await triggerPubsubForGuest({
              sessionId,
              topicId: businessId,
              userId,
            });

            postLogin(result);
            navigate(
              `${ROUTES.BUSINESS_BASE}${businessId}?${stringifySearchParams({
                chat: true,
                skipToNext: true,
              })}`
            );

            return result;
          })
          .catch(async (error) => {
            if (error?.code === FIREBASE_AUTH_ERRORS.EXISTING_ACCOUNT) {
              const credential = TwitterAuthProvider.credentialFromError(error);
              const prevUserId = userId;
              const result = await signInWithCredential(au, credential);
              const newUserId = attachUuidPrefix(
                get(result, "user.uid", ""),
                UUID_PREFIXES.USER
              );
              await transferUserSession({
                existingUserId: newUserId,
                guestUserId: prevUserId,
                topicId: businessId,
              });
              const additonalInfo = await getAdditionalUserInfo(result);
              await upsertUser({
                user: {
                  ...parseResultforPayload(result).user,
                  email: additonalInfo?.profile?.email,
                  username: additonalInfo?.username,
                },
              });
              navigate(
                `${ROUTES.BUSINESS_BASE}${businessId}?${stringifySearchParams({
                  chat: true,
                  skipToNext: true,
                })}`
              );
            } else {
              await handleError(error, SOCIAL_PROVIDERS.TWITTER);
            }
          });
        return;
      }
      const provider = new TwitterAuthProvider();
      const result = await signInWithPopup(
        au,
        provider,
        browserPopupRedirectResolver
      );
      const additonalInfo = await getAdditionalUserInfo(result);
      await upsertUser({
        user: {
          ...parseResultforPayload(result).user,
          email: additonalInfo?.profile?.email,
          username: additonalInfo?.username,
        },
      });
      postLogin(result);
      return result;
    } catch (e) {
      await handleError(e, SOCIAL_PROVIDERS.TWITTER);
    }
  };

  const signIn = async (provider) => {
    setIsSignInOn(true);
    try {
      switch (provider) {
        case SOCIAL_PROVIDERS.TWITTER:
          await twitterSignIn();
          break;
        case SOCIAL_PROVIDERS.FACEBOOK:
          await facebookSignIn();
          break;
        default:
          await googleSignIn();
      }
      setIsSignInOn(false);
      setReloadPage(true);
    } catch (e) {
      setIsSignInOn(false);
      console.log("Error while login", e);
    }
  };

  const providers = [
    SOCIAL_PROVIDERS.GOOGLE,
    // SOCIAL_PROVIDERS.FACEBOOK,
    SOCIAL_PROVIDERS.TWITTER,
  ];

  const logOut = async () => {
    return signOut(au);
  };

  const signInAnonymouslyIfNeeded = () => {
    setIsSignInOn(true);
    try {
      signInAnonymously(au).then(async (result) => {
        const modifiedPayload = {
          user: {
            ...parseResultforPayload(result).user,
            isAnonymousUser: true,
            isNewUser: true,
          },
        };
        await upsertUser(modifiedPayload);
        setIsSignInOn(false);
        return {
          result,
        };
      });
    } catch (error) {
      setIsSignInOn(false);
      console.error("Error signing in anonymously:", error);
    }
  };

  const verifyAuth = (reloadPage = true, sessionId) => {
    // SessionId is required if hello page flow is used
    if (isHelloPage) {
      setSessionId(sessionId);
    }
    if (user === null || user.isAnonymous) {
      setIsLoginOpen(true);
      setReloadPage(reloadPage);
      return false;
    }
    return true;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(au, (currentUser) => {
      try {
        if (isHelloPage && !currentUser) {
          signInAnonymouslyIfNeeded();
        }
        setUser(currentUser);
        const userId = attachUuidPrefix(
          get(currentUser, "uid", ""),
          UUID_PREFIXES.USER
        );
        setUserId(userId);
        getFirebaseUser({ userId }).then(({ data: { user } }) => {
          setFirestoreUser(user);
        });
        currentUser.getIdTokenResult().then((idTokenResult) => {
          const userRoles = idTokenResult?.claims?.roles;
          setUserRoles(
            userRoles === USER_ROLES.END_USER ? [userRoles] : userRoles
          );
          if (checkIsOwner(userRoles)) {
            getBusinesses({ filters: { userId } }).then(
              ({ data: { topics } }) => {
                const businessIds = topics?.map(({ id }) => id) ?? [];
                const selectedBusiness =
                  localStorage.getItem("selectedBusiness");
                if (
                  selectedBusiness === "" ||
                  !selectedBusiness ||
                  !businessIds?.includes(selectedBusiness)
                ) {
                  localStorage.setItem(
                    "selectedBusiness",
                    businessIds?.[0] ?? ""
                  );
                }
                setUserBusinesses(topics ?? []);
                setIsAuthLoading(false);
              }
            );
          } else {
            setIsAuthLoading(false);
          }
        });
      } catch (error) {
        setIsAuthLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <AuthContext.Provider
        value={{
          firestoreUser,
          getAdditionalUserInfo,
          isAuth: user !== null && !isSignInOn,
          logOut,
          signIn,
          user,
          userBusinesses,
          userId,
          userRoles,
          verifyAuth,
        }}
      >
        {!isAuthLoading && children}
      </AuthContext.Provider>
      <LoginModal
        onClose={() => setIsLoginOpen(false)}
        open={isLoginOpen}
        providers={providers}
        signIn={signIn}
      />
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={10000}
        onClose={() => setShowError(false)}
        open={showError}
      >
        <Alert onClose={() => setShowError(false)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

AuthContextProvider.propTypes = {
  au: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  fn: PropTypes.object.isRequired,
};

export const useAuth = () => {
  return useContext(AuthContext);
};
