const { get } = require("lodash");

exports.getCallableFunctionOptions = (optionObj = {}) => {
  return {
    requireLimitedUseAppCheckTokens: get(
      optionObj,
      "requireLimitedUseAppCheckTokens",
      true
    ),
  };
};
