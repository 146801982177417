export const WEB_AUTH_CLIENT_ID =
  "BB-wOM1h1MtlwRsEM6S43hvNidZzMqpv2upus7lvpBCEM7O7cGWmkbAUG26rLfOyLJJYUXwoQ-anT0oeVo7PXaA";

// Decided to keep eip155
// Kept this other to disable wallet button on pop up
export const CHAIN_NAMESPACE = "other";

export const CHAIN_ID = "0x1";

export const FIREBASE_PROJECT_ID = "beassured-d85fc";

export const PARTIES = {
  DEMOCRAT: "Democrat",
  REPUBLICAN: "Republican",
};

export const ANONYMOUS_USER = "Anonymous User";

export const ANONYMOUS_USER_DETAILS = {
  USERNAME: "unknown@unknown.com",
};

export const REVIEW_WIDGET_MODES = {
  CHAT: "CHAT",
  WRITE: "WRITE",
};

export const CATEGORY_IDS = {
  BUSINESSES: 2,
  PRESIDENTS: 1,
};

export const WORKFLOWS = {
  ADMIN: "admin",
  BUSINESSES: "businesses",
  OWNER: "owner",
  PRESIDENTS: "presidents",
};

export const CATEGORY_OPTIONS = [
  // { label: "Businesses", value: "businesses" },
  { label: "Presidential Candidates", value: "presidents" },
];

export const ROUTES = {
  ADMIN_BUSINESS_LISTING: "/admin/businesses",
  ADMIN_PAGE: "/admin/",
  BETA_USER_AGREEMENT: "/beta-user-agreement/",
  BUSINESS_BASE: "/businesses/",
  BUSINESS_LISTING: "/first100/businesses/",
  CREATE_BUSINESS: "/admin/businesses/create/",
  DEFAULT_HOME: "/",
  DISCLAIMER: "/disclaimer/",
  EDIT_BUSINESS: "/admin/businesses/",
  FIRST_100: "/first100/",
  LOGIN: "/login/",
  OWNER: "/owner/",
  PRESIDENT_LISTING: "/presidents/",
  PRIVACY_POLICY: "/privacy-policy/",
  TERMS_AND_CONDITIONS: "/terms-and-conditions/",
  TOPIC_LISTING: "/topics/",
  VOICE_ADMIN_DASHBOARD: "/admin/voice/",
  VOICE_OF_THE_PEOPLE: "/voice/",
};

export const OWNER_SUBROUTES = {
  REVIEWS: "reviews",
  SETTINGS: "settings",
};

export const TWITTER_URL = "https://twitter.com";

export const CHAT_END_SEQUENCE = "###STOP###";

export const CHAT_SEPERATER = "###";

export const ADMIN_ROUTES = ["admin"];

export const BUSINESS_ADMIN_ROUTES = ["businesses"];

export const VOICE_ADMIN_ROUTES = ["voice"];

export const OWNER_ROUTES = ["owner"];

export const HIGH_QUALITY_CUTTOFF = 60;

export const STATUS_CODES = {
  API_KEY_MISSING: 401,
  SUCCESS: 200,
  TOO_MANY_REQUESTS: 429,
};

export const STREAM_ERROR_PROMPT = "An error occurred. Please try again.";

export const STREAM_ERROR_MESSAGE = "An error occurred. Please try again.";

export const PATHS = {
  PRIVACY_POLICY: "/privacy-policy",
  SITEMAP: "",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
};

export const INFORMATION_NAV_LINKS = [
  { label: "Terms and Conditions", path: ROUTES.TERMS_AND_CONDITIONS },
  { label: "Privacy Policy", path: ROUTES.PRIVACY_POLICY },
  { label: "Disclaimer on Use", path: ROUTES.DISCLAIMER },
  { label: "Beta User Agreement", path: ROUTES.BETA_USER_AGREEMENT },
  // { label: "Sitemap", path: PATHS.SITEMAP },
];

export const CHAT_BOT_VIEWS = {
  ALL: "ALL",
  MODAL: "MODAL",
  WIDGET: "WIDGET",
};

export const CHAT_QUESTION_TYPES = {
  RATING: 1,
};

export const CHAT_BOT_MODES = {
  CHAT: "CHAT",
  DISCLAIMER: "DISCLAIMER",
  EDIT: "EDIT",
  PREVIEW: "PREVIEW",
  SIGNUP: "SIGNUP",
};

export const CHAT_ROLE_TYPES = {
  USER: "user",
};

export const SUBSCRIPTION_USER_TYPE = {
  COMPANY: 1,
  PERSON: 2,
};

export const MINIMUM_REQUIRED_CHAT_LENGTH = 4;

export const FIREBASE_BUSINESS_ADMIN_ERRORS = {
  EXISTING_BUSINESS: "Business already exists.",
};

export const FORM_ERROR_MESSAGES = {
  INVALID_EMAIL: "Please enter a valid email address",
  INVALID_URL: "Please enter valid URL",
  INVALID_UUID: "Please enter valid UUID",
  REQUIRED: "Required",
};

export const FIREBASE_ERRORS = {
  INVALID_URL:
    "Firebase Storage: refFromUrl() expected a valid full URL but got an invalid one. (storage/invalid-argument)",
};

export const META_DATA = {
  BETA_USER_AGREEMENT: {
    DESCRIPTION:
      "Discover BeAssured, the trusted online review platform with Review Autopilot. Revolutionize transparency and trust, automate responses, nurture engagement, and build a thriving reputation. Join us today!",
    TITLE: "Beta User Agreement by BeAssured",
  },
  BUSINESS: {
    DESCRIPTION:
      "Join BeAssured's exclusive First 100 program and unlock powerful benefits for your business. Get a complimentary SEO audit, free access to our platform, and be part of a thriving community. Don't miss this opportunity!",
    TITLE: "BeAssured First 100",
  },
  BUSINESS_DETAILS: {
    DESCRIPTION:
      "Discover the true essence of this small business through real-time customer conversations. Explore authentic ratings and reviews to get a complete picture. Engage with our Conversational Bot to capture the essence of your experience.",
    TITLE: "Explore this small business's reviews and conversations",
  },
  BUSINESS_REVIEWS: {
    DESCRIPTION:
      "Get the complete picture of small businesses by delving into real-time conversations. Discover what customers are talking about and join the conversation to share your experience and support local businesses.",
    TITLE: "Browse all conversational reviews for small businesses",
  },
  CONVERSATIONS: {
    DESCRIPTION:
      "Start a conversation about your thoughts on 2024 presidential candidates' beliefs, policy stances, speeches and more on Voice. Decide who to vote for by sharing your election opinions with others.",
    TITLE: "Conversation - Voice by BeAssured",
  },
  DEFAULT: {
    DESCRIPTION:
      "Discover BeAssured, the trusted online review platform with Review Autopilot. Revolutionize transparency and trust, automate responses, nurture engagement, and build a thriving reputation. Join us today!",
    TITLE: "BeAssured",
  },
  DISCLAIMER: {
    DESCRIPTION:
      "Discover BeAssured, the trusted online review platform with Review Autopilot. Revolutionize transparency and trust, automate responses, nurture engagement, and build a thriving reputation. Join us today!",
    TITLE: "Disclaimer on Use by BeAssured",
  },
  FIRST100: {
    DESCRIPTION:
      "Join BeAssured's exclusive First 100 program and unlock powerful benefits for your business. Get a complimentary SEO audit, free access to our platform, and be part of a thriving community. Don't miss this opportunity!",
    TITLE: "BeAssured First 100",
  },
  PRIVACY: {
    DESCRIPTION:
      "Discover BeAssured, the trusted online review platform with Review Autopilot. Revolutionize transparency and trust, automate responses, nurture engagement, and build a thriving reputation. Join us today!",
    TITLE: "Privacy policy by BeAssured",
  },
  TERMS: {
    DESCRIPTION:
      "Discover BeAssured, the trusted online review platform with Review Autopilot. Revolutionize transparency and trust, automate responses, nurture engagement, and build a thriving reputation. Join us today!",
    TITLE: "Teams and Conditions by BeAssured",
  },
  TOPIC: {
    DESCRIPTION:
      "Discuss 2024 presidential candidates' beliefs, policy stances, speeches and more on Voice. Decide who to vote for by sharing your election opinions with others.",
    TITLE: "Candidacy Profile - Voice by BeAssured",
  },
  TOPICS: {
    DESCRIPTION:
      "We’re a productive, drama-free forum for political discourse. Voice by BeAssured facilitates meaningful conversations about politics. Sign up to be the first to experience it.",
    TITLE: "Voice by BeAssured",
  },
  VOICE: {
    DESCRIPTION:
      "Engage in thought-provoking political conversations and gain insights into public opinion with Voice by BeAssured. Explore diverse perspectives and discover the pulse of the people. Join the conversation today!",
    TITLE: "Voice by BeAssured",
  },
};

export const CANDIDATE_FILTER_VALUES = {
  ALL: "ALL",
};

export const TOPIC_TYPES = {
  GENERAL: 1,
  SPECIFIC: 2,
};

export const REF_ID_TYPE = {
  ENTITY: 1,
  HIGHLIGHT: 3,
  RESPONSE: 4,
  TOPIC: 2,
};

export const FIREBASE_AUTH_ERRORS = {
  DUPLICATE_EMAIL: "auth/account-exists-with-different-credential",
  EMAIL_ALREADY_IN_USE: "auth/email-already-in-use",
  EXISTING_ACCOUNT: "auth/credential-already-in-use",
};

export const AUTH_ERRORS = {
  DUPLICATE_EMAIL:
    "This email address is already associated with a BeAssured account. Please log in with the same provider you used last time",
};

export const CONVERSATION_FILTERS = {
  HIGHEST_RATED: { field: "ratingCH", label: "Highest Rated", value: "desc" },
  LOWEST_RATED: { field: "ratingCH", label: "Lowest Rated", value: "asc" },
  MOST_RECENT: { field: "updatedAt", label: "Most Recent", value: "desc" },
};

export const CHAT_BOT_PROMPT = {
  BUSINESSES: "Help the community by sharing your experience",
  TOPICS: "Share your opinion on this topic",
};

export const CHAT_BOT_PROMPT_TITLE = {
  BUSINESSES: "Thoughts on this?",
  TOPICS: "Add to the discourse",
};

export const ZEMBRA_NETWORKS = {
  GOOGLE: "google",
};

export const FIREBASE_FUNCTIONS = {
  ADD_COMMENT: "addComment",
  ADMIN_CHANGE_STATUS_TOPIC: "adminchangeStatusTopic",
  ADMIN_DELETE_TOPIC: "adminDeleteTopic",
  CREATE_BUSINESS_TOPIC: "createBusinessTopic",
  CREATE_ZEMBRA_SUBSCRIPTION: "createZembraSubscription",
  DELETE_REVIEW_COMMENT: "deleteReviewComment",
  GET_ADMIN_TOPIC: "getAdminTopic",
  GET_ADMIN_TOPICS: "getAdminTopics",
  GET_BUSINESS_DETAILS: "getBusinessDetails",
  GET_BUSINESS_LISTING: "getBusinessListing",
  GET_CONVERSATION_HIGHLIGHTS: "getConversationHighlights",
  GET_EXTERNAL_REVIEWS: "getExternalReviews",
  GET_PRESIDENTS: "getPresidents",
  GET_RECENT_TOPICS: "getRecentTopics",
  GET_REVIEW_COMMENTS: "getReviewComments",
  GET_SESSION_DETAILS: "getSessionDetails",
  GET_TOPICS: "getTopics",
  GET_USER: "getUser",
  GET_USER_BUSINESS: "getUserBusiness",
  GET_USER_TOPIC_DETAILS: "getUserTopicDetails",
  GET_USER_TOPIC_LISTING: "getUserTopicListing",
  GET_USERS: "getUsers",
  INSERT_TOPICS: "insertTopics",
  SAVE_GENERAL_RESPONSES: "saveGeneralresponses",
  SAVE_USER_SEARCH: "saveUserSearch",
  TRANSFER_USER_SESSION: "transferUserSession",
  TRIGGER_PUBSUB_FOR_GUEST: "triggerPubsubFlowForGuestUser",
  UPDATE_BUSINESS_TOPIC: "updateBusinessTopic",
  UPDATE_TOPIC: "updateTopic",
  UPSERT_SESSION: "upsertSession",
  UPSERT_USER: "upsertUser",
};

export const TOPIC_CATEGORY = {
  BUSINESS: 2,
  VOICE: 1,
};

export const TOPIC_SUB_TYPE = {
  ENGAGEMENT: 2,
  EVENT: 3,
  FEEDBACK: 1,
};

export const MAX_SELECTABLE_KEYWORDS = 5;

export const DEFAULT_KEYWORDS_OBJECT = {
  name: "",
  summary: "",
};

export const VOICE_ROUTES = ["voice", "topics", "presidents", "login"];

export const INITAL_RATING_DELIMITER = " out of ";

export const ITEMS_PER_PAGE = {
  ADMIN_BUSINESS_LISTING: 12,
  ADMIN_TOPICS_LISTING: 12,
  BUSINESS_LISTING: 12,
  BUSINESS_OWNER_CONVERSATIONS: 8,
  COMMENTS_LISTING: 6,
  OWNER_COMMENTS_LISTING: 6,
  RECENT_TOPICS: 5,
  TOP_COMMENTS: 2,
};

export const SOCIAL_PROVIDERS = {
  FACEBOOK: "Facebook",
  GOOGLE: "Google",
  TWITTER: "Twitter",
};

export const SOCIAL_LINKS = {
  DISCORD: "https://discord.com/invite/utgFhbh9yz",
  EMAIL: "hello@beassured.io",
  FACEBOOK: "https://www.facebook.com/profile.php?id=100093917362846",
  TWITTER: "https://twitter.com/beassuredio",
};

export const USER_ROLES = {
  BUSINESS_ADMIN: 2,
  BUSINESS_OWNER: 3,
  END_USER: 4,
  VOICE_ADMIN: 1,
};

export const UUID_PREFIXES = {
  BUSINESS: "babus",
  EXTREVIEW: "baextreview:",
  HIGHLIGHT: "bahighlight:",
  POINT: "bapoints:",
  PRESIDENT: "bacandi:",
  REACTION: "bareaction:",
  RESPONSE: "baresponse:",
  REVIEW: "bareview:",
  SEARCH: "basearch:",
  SESSION: "basession:",
  TOPIC: "batopic:",
  USER: "bauser:",
  WAITLIST: "bawaitlist",
};

export const ADMIN_BUTTON_LABELS = {
  ACCOUNT_SETTINGS: "Account Settings",
  CREATE_BUSINESS: "Grant Access",
  MANAGE_BUSINESSES: "Business Admin",
  VIEW_REVIEWS: "Manage Business",
  VOICE_DASHBOARD: "Voice",
};

export const PROFILE_MENU_LABELS = {
  ADMIN_PANEL: "Admin Panel",
  LOGOUT: "Log Out",
  MANAGE_BUSINESS: "Manage Business",
};

export const BUSINESS_ONBOARDING_STEP = {
  EDIT: 2,
  INVITE: 3,
};

export const CHAT_API_ENDPOINTS = {
  GENERATE_RESPONSE_WITH_TONE: "generateResponseWithTone",
  GENERATE_REVIEW: "generateReview",
  GENERATE_REVIEW_WITHOUT_STREAMING: "generateConversationalReview",
  SESSION_CONVERSE: "sessionConverse",
};

export const WOW_MEH_MAX_COUNT = 5;

export const AUTO_RENEW_ZEMBRA_SUBCRIPTIONS = true;

export const TOPIC_STATUS = {
  ACTIVE: 1,
  INACTIVE: 0,
};

export const TOPIC_QUESTION_TYPES = {
  RATING: 1,
};

export const GOOGLE_SEARCH_URL = "https://www.google.com/maps/search/";

export const REVIEW_CARD_VARIATIONS = {
  OWNER: "OWNER",
  STANDARD: "STANDARD",
};

export const REVIEW_SOURCE = {
  BEASSURED: "beassured",
  GOOGLE: "google",
};

export const MAX_PREVIEW_IMAGES = 6;

export const REVIEW_SOURCE_LABEL = {
  BEASSURED: "BeAssured",
  GOOGLE: "Google",
};

export const REVIEW_TABS = {
  BEASSURED_CONVERSATIONS: {
    label: "Beassured Conversations",
    value: 0,
  },
  GOOGLE_REVIEWS: {
    label: "Google Reviews",
    value: 1,
  },
};

export const RESPONSE_TONES = [
  "professional",
  "appreciative",
  "apologetic",
  "constructive",
  "friendly",
];

export const FRESH_MESSAGE_COUNT_FOR_PROMPT = 2;

export const SOURCED_FROM = {
  FIRST100: 1,
  OUTSCRAPER: 2,
};

export const INITIAL_QUESTION_VARIABLES = {
  FIRST_NAME: "{{firstName}}",
};

export const STRUCTURED_DATA_TYPES = {
  AGGREGATED_RATING: "AggregateRating",
  ARTICLE: "Article",
  BREADCRUMB_LIST: "BreadcrumbList",
  DISCUSSION_FORUM: "DiscussionForumPosting",
  EVENT: "Event",
  LOCAL_BUSINESS: "LocalBusiness",
  ORGANIZATION: "Organization",
  PERSON: "Person",
  REVIEW: "Review",
  WEBPAGE: "WebPage",
  WEBSITE: "WebSite",
};
