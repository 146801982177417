import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { trim } from "lodash";
import theme from "../../../utilities/theme";
import { VOICE_ROUTES } from "../../../utilities/constants";

const getThemeVariation = (theme, location) => {
  const route = trim(location.pathname, "/").split("/")?.[0];
  if (VOICE_ROUTES.includes(route)) return theme.voice;
  return theme.default;
};

const ThemeProvider = ({ children, location }) => {
  const themeVariant = getThemeVariation(theme, location);
  return (
    <MuiThemeProvider theme={themeVariant.muiTheme}>
      <StyledThemeProvider theme={themeVariant}>{children}</StyledThemeProvider>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
