exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-businesses-[id]-index-js": () => import("./../../../src/pages/admin/businesses/[id]/index.js" /* webpackChunkName: "component---src-pages-admin-businesses-[id]-index-js" */),
  "component---src-pages-admin-businesses-[id]-invite-index-js": () => import("./../../../src/pages/admin/businesses/[id]/invite/index.js" /* webpackChunkName: "component---src-pages-admin-businesses-[id]-invite-index-js" */),
  "component---src-pages-admin-businesses-create-index-js": () => import("./../../../src/pages/admin/businesses/create/index.js" /* webpackChunkName: "component---src-pages-admin-businesses-create-index-js" */),
  "component---src-pages-admin-businesses-index-js": () => import("./../../../src/pages/admin/businesses/index.js" /* webpackChunkName: "component---src-pages-admin-businesses-index-js" */),
  "component---src-pages-admin-index-js": () => import("./../../../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-voice-[id]-index-js": () => import("./../../../src/pages/admin/voice/[id]/index.js" /* webpackChunkName: "component---src-pages-admin-voice-[id]-index-js" */),
  "component---src-pages-admin-voice-index-js": () => import("./../../../src/pages/admin/voice/index.js" /* webpackChunkName: "component---src-pages-admin-voice-index-js" */),
  "component---src-pages-admin-voice-maketopic-index-js": () => import("./../../../src/pages/admin/voice/maketopic/index.js" /* webpackChunkName: "component---src-pages-admin-voice-maketopic-index-js" */),
  "component---src-pages-beta-user-agreement-index-js": () => import("./../../../src/pages/beta-user-agreement/index.js" /* webpackChunkName: "component---src-pages-beta-user-agreement-index-js" */),
  "component---src-pages-businesses-[id]-hello-index-js": () => import("./../../../src/pages/businesses/[id]/hello/index.js" /* webpackChunkName: "component---src-pages-businesses-[id]-hello-index-js" */),
  "component---src-pages-businesses-[id]-index-js": () => import("./../../../src/pages/businesses/[id]/index.js" /* webpackChunkName: "component---src-pages-businesses-[id]-index-js" */),
  "component---src-pages-businesses-[id]-reviews-index-js": () => import("./../../../src/pages/businesses/[id]/reviews/index.js" /* webpackChunkName: "component---src-pages-businesses-[id]-reviews-index-js" */),
  "component---src-pages-disclaimer-index-js": () => import("./../../../src/pages/disclaimer/index.js" /* webpackChunkName: "component---src-pages-disclaimer-index-js" */),
  "component---src-pages-first-100-businesses-index-js": () => import("./../../../src/pages/first100/businesses/index.js" /* webpackChunkName: "component---src-pages-first-100-businesses-index-js" */),
  "component---src-pages-first-100-index-js": () => import("./../../../src/pages/first100/index.js" /* webpackChunkName: "component---src-pages-first-100-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-owner-[id]-reply-index-js": () => import("./../../../src/pages/owner/[id]/reply/index.js" /* webpackChunkName: "component---src-pages-owner-[id]-reply-index-js" */),
  "component---src-pages-owner-[id]-reviews-index-js": () => import("./../../../src/pages/owner/[id]/reviews/index.js" /* webpackChunkName: "component---src-pages-owner-[id]-reviews-index-js" */),
  "component---src-pages-owner-[id]-settings-index-js": () => import("./../../../src/pages/owner/[id]/settings/index.js" /* webpackChunkName: "component---src-pages-owner-[id]-settings-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-topics-[id]-index-js": () => import("./../../../src/pages/topics/[id]/index.js" /* webpackChunkName: "component---src-pages-topics-[id]-index-js" */),
  "component---src-pages-topics-[id]-reviews-index-js": () => import("./../../../src/pages/topics/[id]/reviews/index.js" /* webpackChunkName: "component---src-pages-topics-[id]-reviews-index-js" */),
  "component---src-pages-topics-index-js": () => import("./../../../src/pages/topics/index.js" /* webpackChunkName: "component---src-pages-topics-index-js" */),
  "component---src-pages-voice-index-js": () => import("./../../../src/pages/voice/index.js" /* webpackChunkName: "component---src-pages-voice-index-js" */)
}

