import PropTypes from "prop-types";
import React, { createContext, useContext } from "react";

const FirebaseContext = createContext();

export const FirebaseProvider = ({ children, app, au, cs, db, fn }) => {
  return (
    <FirebaseContext.Provider
      value={{
        app,
        au,
        cs,
        db,
        fn,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  app: PropTypes.object.isRequired,
  au: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  cs: PropTypes.object.isRequired,
  db: PropTypes.object.isRequired,
  fn: PropTypes.object.isRequired,
};

export const useFirebase = () => {
  return useContext(FirebaseContext);
};
