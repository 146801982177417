/* eslint-disable sort-keys */
import { createTheme } from "@mui/material";

const blue = "#006cfe";
const blue2 = "#085AC9";
const errorRed = "#d74242";
const red = "#ff3b30";
const green = "#23530B";
const holoGreen = "#14B8A6";
const lightestBlue = "#f2f8ff";
const yellow = "#FAD24B";
const goldenYellow = "#FCA917";
const gray1 = "#dddddd";
const gray2 = "#929292";
const gray3 = "#f2f2f2";
const gray4 = "#C8CEAD";
const gray5 = "#d9d9d9";
const gray6 = "#FBFBF9";
const gray7 = "#f6f6f6";
const gray8 = "#F8F9FA";
const gray9 = "#F0F4F7";
const gray10 = "#c4c4c4";
const gray11 = "#BFBFBF";
const gray12 = "#F8FAFC";
const gray13 = "#ededed";
const gray14 = "#fcfcfc";
const gray15 = "#737373";
const gray16 = "#DFE4E9";
const gray17 = "#f5f5f7";
const gray18 = "#FAFAFA";
const skyBlue = "#f6fbff";
const lighterBlack = "#3A3A3C";
const darkGray = "#696C80";
const white = "#FFFFFF";
const lightGray = "#e2e2e2";
const black = "#000000";
const midnightBlue = "#0E2442";
const violet = "#241588";
const lighterViolet = "#2C1C8B";
const lightestViolet = "#e5f0ff";
const purple = "#472AA5";
const darkBlue = "#01006C";
const darkViolet = "#3C1B4F";
const darkerPurple = "#3F259E";
const darkerBlue = "#29188C";
const brightYellow = "#FFA800";
const lightYellow = "#fcedcc";
const lighterYellow = "#C8CEAD";
const lightBlack = "#271E1B";
const mudBrown = "#6D551D";
const transparent = "transparent";
const selectedGreen = "#33cf4d";
const primaryAccent = blue;
const primaryAccentLight = lightestBlue;

const colors = {
  black,
  blue,
  blue2,
  brightYellow,
  darkBlue,
  darkerBlue,
  darkerPurple,
  darkGray,
  darkViolet,
  errorRed,
  goldenYellow,
  gray1,
  gray2,
  gray3,
  gray4,
  gray5,
  gray6,
  gray7,
  gray8,
  gray9,
  gray10,
  gray11,
  gray12,
  gray13,
  gray14,
  gray15,
  gray16,
  gray17,
  gray18,
  green,
  red,
  holoGreen,
  lightBlack,
  lightGray,
  lightYellow,
  lighterBlack,
  lighterViolet,
  lighterYellow,
  lightestBlue,
  lightestViolet,
  midnightBlue,
  mudBrown,
  skyBlue,
  primaryAccent,
  primaryAccentLight,
  purple,
  selectedGreen,
  transparent,
  violet,
  white,
  yellow,
};

const fontSizes = {
  none: "0",
  xxxs: "0.2rem",
  xxsmaller: "0.3rem",
  xxs: "0.4rem",
  xsmaller: "0.5rem",
  xs: "0.6rem",
  ss: "0.7rem",
  s: "0.8rem",
  ms: "0.9rem",
  m: "1rem",
  ml: "1.1rem",
  lg: "1.2rem",
  larger: "1.3rem",
  xl: "1.4rem",
  xxl: "1.6rem",
  xxxl: "1.8rem",
  big: "2rem",
  xbig: "2.25rem",
  huge: "2.5rem",
  extreme: "3rem",
  largerEx: "5rem",
};

const shadows = {
  bottom: "0px 4px 24px rgba(9, 16, 43, 0.04)",
  darkerDrop: "0px 0px 25px 2px #09102B1F",
  drop: "0px 0px 24px rgba(9,16, 43, 0.04)",
};

const borders = {
  none: "0px",
  large: "5px solid",
  normal: "1px solid",
  slightlyLarge: "2px solid",
  thick: "10px solid",
};

const widths = {
  xl: "680px",
  s: "320px",
  screen: "100vw",
  xs: "240px",
  xxs: "120px",
};

const radii = {
  none: "0px",
  xxs: "5px",
  xsmaller: "7px",
  xs: "10px",
  s: "15px",
  m: "25px",
  lg: "50px",
  half: "50%",
};

const fontWeights = {
  light: 450,
  normal: 500,
  bold: 700,
  black: 900,
};

const fontFamily = {
  primary: "CircularStd",
  secondary: "CircularXXSub-Book",
  tertiary: "CircularXXSub-Book",
};

const spacing = {
  none: "0",
  tiny: "0.1rem",
  xxxsmaller: "0.2rem",
  xxxs: "0.25rem",
  xxs: "0.375rem",
  xs: "0.5rem",
  s: "0.75rem",
  ms: "1rem",
  m: "1.25rem",
  ml: "1.5rem",
  lg: "2rem",
  larger: "2.5rem",
  xl: "3.25rem",
  xlarger: "4.25rem",
  xxl: "5.25rem",
  xxlarger: "6.25rem",
  xxxl: "8.5rem",
};

const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: primaryAccent,
    },
  },
});

export const generateVariants = ({
  borders,
  colors,
  fontFamily,
  fontSizes,
  fontWeights,
  radii,
  spacing,
}) => {
  return {
    button: {
      simple: {
        color: colors.primaryAccent,
        fontFamily: fontFamily.primary,
        fontWeight: fontWeights.bold,
      },
      filled: {
        backgroundColor: colors.primaryAccent,
        borderRadius: radii.xxs,
        color: colors.white,
        fontFamily: fontFamily.primary,
        minWidth: "fit-content",
        paddingX: spacing.m,
        paddingY: spacing.xs,
      },
      outlined: {
        border: borders.normal,
        borderColor: colors.primaryAccent,
        borderRadius: radii.xxs,
        color: colors.primaryAccent,
        fontFamily: fontFamily.primary,
        minWidth: "fit-content",
        paddingX: spacing.m,
        paddingY: spacing.xs,
      },
      "outlined-filled": {
        ":disabled": {
          backgroundColor: colors.gray3,
          borderColor: colors.gray2,
          color: colors.gray2,
        },
        backgroundColor: colors.primaryAccentLight,
        border: borders.normal,
        borderColor: colors.primaryAccent,
        borderRadius: radii.xxs,
        color: colors.primaryAccent,
        fontFamily: fontFamily.primary,
        minWidth: "fit-content",
        padding: spacing.xs,
      },
    },
    autocomplete: {
      rounded: {
        "& .MuiAutocomplete-inputRoot": {
          borderRadius: radii.lg,
          paddingLeft: "20px",
        },
        "& .MuiInputLabel-root": {
          fontSize: fontSizes.s,
        },
        "& .MuiInputLabel-shrink": {
          background: colors.white,
          marginLeft: "20px",
          paddingLeft: "10px",
          paddingRight: 0,
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: radii.lg,
          fontSize: fontSizes.s,
          legend: {
            marginLeft: "30px",
          },
        },
        "& .MuiutLabel-outlined": {
          paddingLeft: "20px",
        },
      },
    },
    switch: {
      iOS: {
        "& .MuiSwitch-switchBase": {
          "&.Mui-checked": {
            "& + .MuiSwitch-track": {
              backgroundColor: colors.selectedGreen,
              border: borders.none,
              opacity: 1,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
            color: colors.white,
            transform: `translateX(${spacing.ms})`,
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            border: borders.large,
            borderColor: colors.white,
            color: colors.selectedGreen,
          },
          margin: spacing.tiny,
          padding: spacing.none,
          transitionDuration: "300ms",
        },
        "& .MuiSwitch-thumb": {
          boxSizing: "border-box",
          height: spacing.m,
          width: spacing.m,
        },
        "& .MuiSwitch-track": {
          backgroundColor: colors.gray1,
          borderRadius: radii.lg,
          opacity: 1,
        },
        height: spacing.ml,
        padding: spacing.none,
        width: spacing.larger,
      },
      iOSDefault: {
        "& .MuiSwitch-switchBase": {
          "&.Mui-checked": {
            "& + .MuiSwitch-track": {
              backgroundColor: colors.primaryAccent,
              border: borders.none,
              opacity: 1,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
            color: colors.white,
            transform: `translateX(${spacing.ms})`,
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            border: borders.large,
            borderColor: colors.white,
            color: colors.primaryAccent,
          },
          margin: spacing.tiny,
          padding: spacing.none,
          transitionDuration: "300ms",
        },
        "& .MuiSwitch-thumb": {
          boxSizing: "border-box",
          height: spacing.m,
          width: spacing.m,
        },
        "& .MuiSwitch-track": {
          backgroundColor: colors.gray1,
          borderRadius: radii.lg,
          opacity: 1,
        },
        height: spacing.ml,
        padding: spacing.none,
        width: spacing.larger,
      },
    },
  };
};

const variants = generateVariants({
  borders,
  colors,
  fontFamily,
  fontSizes,
  fontWeights,
  radii,
  spacing,
});

export default {
  borders,
  colors,
  fontFamily,
  fontSizes,
  fontWeights,
  muiTheme,
  radii,
  shadows,
  spacing,
  variants,
  widths,
};
